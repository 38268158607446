
import React from 'react'

import { LinkStyleCom } from "../../../styles/jsStyles/LinkStyle";
// import Select from 'react-select'

//transition Animation
import { motion } from 'framer-motion'

//style
import { WorkDetailETCStyleCom } from "../../../styles/jsStyles/WorkStyles/WorkDetailETCStyle"

import pic1 from "../../../assets/images/work/ETC/esports/esports.jpg"
import pic2 from "../../../assets/images/work/ETC/esports/esports2.jpg"
import pic3 from "../../../assets/images/work/ETC/esports/esports3.jpg"

function WorkDetailETC2({history, match }) {

    // const userLogin = useSelector(state => state.userLogin)
    // const { userInfo } = userLogin

    // useEffect( () => {

    //     if(userInfo == null ){
    //         history.push('/warningscreen')
    //     }else{
    //         if( !userInfo.isAdmin){
    //             history.push('/warningscreen')
    //         }
    //     }
        
    // }, [history, userInfo])

    return (
        <>
                    <motion.div
                        initial = {{ translateY:60, opacity: 0 }}
                        animate = {{ translateY:0, opacity: 1 }}
                        exit={{ translateY:60, opacity: 0 }}
                        transition={{duration: 0.5}}
                    >
                    <WorkDetailETCStyleCom>
                        <div className="work-contents-container">
                            
                            {/* <ContentsBanner bannerTitle={"AI DETAIL"}/> */}

                            <div className="work-title-container">
                                
                                <div className="work-detail-info">
                                    <div className="work-info-category">Sports Industry</div>
                                    <div className="slash-box">/</div>
                                    <div className="work-info-published">Facility</div>
                                    <div className="slash-box">/</div>
                                    <div className="work-info-author">column</div>
                                    <div className="slash-box">/</div>
                                    <div className="work-info-visit">Comunity</div>
                                </div>
                                <div className="work-detail-title">
                                    <span>E-Sports as a trendy media that captivates modern consumers</span>
                                    <span>E-Sports 현대소비자를 사로잡는 트렌디(Trendy)한 미디어로</span>
                                </div>

                            </div>

                            <div className="work-contents">
                                <div className="project-purpose-container">
                                    <div className="project-purpose-title">E-Sports 현대소비자를 사로잡는 트렌디(Trendy)한 미디어로 발전하라!</div>
                                    <div className="project-purpose-content"> 
                                    <p>
                                        &nbsp;&nbsp;
                                        세계적으로 급성장하고 있는 e-Sports 산업 속에서 현재 가장 많은 관심을 받고 있는 게임을 물어본다면, 그 대답은 당연히‘배틀그라운드’ 이다. 이 게임은 최대 100명의 플레이어가 함께 경기를 하며, 고립된 섬에서 시작하여 섬에 있는 각종 무기, 시설, 차량 등을 전략적으로 이용하여, 마지막까지 살아남는 서바이벌(survival)게임이다. 회사 스팀(Steam)을 통해, 세계 시장에 먼저 공개된 ‘배틀그라운드’는 유료 테스트 버전(Early Access)임에도 불구하고, 판매량이 1800만 장을 넘어가는 저력을 과시하는 중 이다(2017, 최상현). 이러한 인기에 힘입어 ‘배틀그라운드’는 벌써 게임의 e-Sports 화에 속도를 올리고 있다. 국내외로는 ‘배틀그라운드’ 프로 게임단이 창설되었고 지난 8월, 독일 쾰른(Cologne)에서 열린 1차 ‘게임스컴 배틀그라운드 인비테이셔널’대회에 이어, 현재는 2차 ‘카카오게임즈 배틀그라운드 인비테이셔널’대회가 진행되고 있는 상황이다(2017, 윤민섭). 이러한, ‘배틀그라운드’의 세계적인 흥행은, 게임이 국내 게임개발사에서 만들어진 ‘국산’이라는 점에서 큰 의미가 있다. 우리나라는 세계 모든 e-Sports 대회를 석권하는 게임 강대국임에도 불구하고, 항상 외산(外産) 게임이 세계 e-Sports대회를 주도하고 있다는 명확한 한계를 가지고 있었다. 그렇기 때문에 국산 게임인 ‘배틀그라운드’의 흥행은 앞으로 우리나라 게임들이 세계에 진출하여 e-Sports 시장을 주도하는 자리로 나아가도록, 길을 개척해 줄 것이라는 큰 기대를 받고 있다(2017, 서영준).
                                        <br/><br/>
                                        이렇게 탄탄대로를 걷고 있는 ‘배틀그라운드’도 현재 큰 고민에 빠져있는데, 그 이유는 바로 ‘옵서버(Observer)’ 라고 불리는 e-Sports 관전 시스템 때문이다. ‘옵서버’는 ‘관찰하다’라는 뜻을 가진 ‘Observe’와 ‘~하는 사람’이라는 뜻을 가진 접미사 ‘Er’이 합쳐져서 만들어진 단어이다(2017, 박준영). e-Sports에서 ‘옵서버’의 역할은, 다른 일반 스포츠에서 카메라맨의 역할과 비슷하다고 볼 수 있다. ‘옵서버’는 게임이 진행되는 동안 끊임없이 게임 주요 장면들을 포착해야 하고, 방송될 장면들을 선별하여 관중들에게 보여줘야 한다. 하지만, 다른 일반 스포츠와 달리 e-Sports만이 가지고 있는 여러 고유한 특징들이 ‘옵서버’의 역할을 어렵게 만들고 있다.
                                        <br/><br/>
                                        먼저, 다른 스포츠와 달리 e-Sports는 게임 결과에 큰 영향을 주는 장면들이 동시 다발적으로 일어난다는 특징이 있다. 다른 모든 스포츠는 대부분 게임에서 사용되는 ‘공’을 위주로 중요한 사건들이 발생한다. 그렇기 때문에, 중계 카메라가 ‘공’만 충실히 따라간다면 사실상 시청자들에게 가장 중요한 일차적인 정보들은 모두 전달할 수 있는 것이다. 하지만, e-Sports에서는 다르다. 게임에서 발생하는 모든 사사로운 사건들이 게임 결과로 직결되는 아주 중요한 원인이 된다. 예로 한번, 스타크래프트(Star Craft) 게임을 중계한다고 가정해 보자. 만약 한 선수가 소수의 유닛을 이용해 적의 본진 뒤쪽을 공격하여 상대를 교란하고, 나머지 모든 유닛으로 정면을 총공격하는 전략을 실행하고 있다면, ‘옵서버’는 어떠한 장면이 더 중요한지 빠르게 판단하여, 총공세를 펼치고 있는 정면의 모습을 보여줄지, 적의 본진 뒤쪽에서 상대를 교란하고 있는 소수의 유닛을 시청자들에게 보여줄지 결정해야만 한다. 그나마 스타크래프트(Star Craft) 게임과 같이 1:1로 진행되는 e-Sports의 경기종목은 장면의 중요도를 판단하기가 비교적 수월하다. 하지만, 현대 들어서, 다수(多數)대 다수로 이루어지는 e-Sports 게임이 유행하면서 ‘옵서버’의 역할은 더욱더 어려워지고 있다.
                                        <br/><br/>
                                        올해 10월까지 무려 42주 동안, PC방 점유율에서 부동의 1위를 차지했던 리그오브레전드(League of Legend) 게임은(2017, 서삼광), 종목의 최대 규모 대회인 ‘롤드컵(League of Legend World Championship)’ 결승전의 시청자 수가 4천3백만 명을 넘을 정도로 고도의 e-Sports 화가 진행된 종목이다(2017, 박범). 이 리그오브레전드(League of Legend) 게임 은 ‘옵서버’들에게는 너무도 까다로운 e-Sports 종목이다. 5:5로 진행되는 이 게임에서 선수들의 역할은 크게 상단(Top), 중단(Mid), 정글(Jungle) 그리고 하단(Bottom)으로 나누어진다. 선수들은 각자가 맡은 구역으로 들어오는 중립 유닛들과 상대 팀 선수와 끊임없이 교전하여 성장해야 한다. 그리고, 결국엔 모든 팀원이 모여 적의 본진을 먼저 파괴하는 팀이 이기는 게임이다. 리그오브레전드(League of Legend)는 게임의 시작과 동시에, 선수들이 각자 분담한 역할 구역에서 끊임없이 교전이 발생한다. 때로는 2:2, 3:3 전투가 동시에 발생하기도 하며, 1:3 과같이 소수(小數)대 다수의 전투가 동시에 일어나기도 한다. 결국, ‘옵서버’는 게임 시작과 동시에, 게임 장면들의 중요도를 끊임없이 저울질하여 시청자들에게 보여줄 화면을 결정하는 과정을 게임이 끝날 때까지 반복해야 하는 것이다.
                                       </p>
                                       <div className="img-caption-container">
                                            <img src={pic1} alt="pic1" />
                                            <div className="img-caption">[사진 1. ‘공’의 움직임을 위주로 방송되는 축구의 중계 모습과 동시다발적으로 사건이 발생하는 리그오브레전드(League of Legend) 게임의 중계모습][출처 KBS, http://blog.naver.com/asller/220700731403]</div>
                                        </div>
                                        <p>
                                        다시 돌아가서, 이렇게 10명의 선수가 진행하는 리그오브레전드(League of Lengend)의 ‘옵서버’ 시스템도 큰 어려움을 겪고 있는데, 심지어 최대 100명이라는 엄청난 수의 선수들이 동시에 게임을 진행하는 “배틀그라운드”가 처한, 현재 고민은 얼마나 깊을지 쉽게 가늠할 수 있다. 하지만, 이러한 관전 시스템에 대한 문제는, 게임이 세계 e-Sports 종목으로 발돋움하는데 꼭 해결해야 할 과제이다.
                                        <br/><br/>
                                        ‘옵서버’ 시스템의 중요성은 게임‘오버워치’의 부진(不振) 에서 쉽게 확인할 수 있다. 블리자드(Blizzard) 회사에서 개발한 ‘오버워치’ 게임은 FPS 1인칭 슈팅 게임이다. ‘오버워치’ 게임은 발매되고 3주 만에 리그오브레전드(League Of Legend)를 제치고 PC방 점유율 1위를 달성하면서, 긴 시간 계속되었던 리그오브레전드(League Of Legend)의 독주체제를 무너뜨릴 유일한 대항마(對抗 馬)로 평가받았다. 하지만, ‘오버워치’의 황금기는 오래가지 않았다. 2016년 6월 26일, 30.36%라는 PC방 점유율을 기록하며 1위를 차지했던 ‘오버워치’는 2017년에 들어서 20%대로 하락하였고, 올해 10월 점유율이 9%까지 추락했다. 서비스를 시작한 후, 1년 6개월 만에 무려 20%가 넘는 점유율이 하락한 것이다(2017, 원태영).
                                        <br/><br/>
                                        ‘오버워치’의 추락에는 여러 가지 이유가 있지만, 그중에서도 게임의 관전 시스템의 문제는 지금까지 지속해서 지적되는 부분이다. 당시 엄청난 흥행몰이와 함께 개최된, ‘오버워치’의 e-Sports 대회들은 예상보다 많은 인기를 끌지 못했다. 그 이유는 간단했다. 게임을 직접 하는 것과 비교해, 보는 것이 너무도 재미없었기 때문이다. 대회 당시, ‘옵서버’들은 중요한 장면들을 놓치기 일쑤였고, 게임 캐릭터들이 한데 모여 뒤엉키는 복잡한 상황들을 지속해서 송출했다. 당시 대부분의 시청자가 ‘오버워치’ 게임을 즐겨 하는 사용자들이었음에도 불구하고, 방송만 봐서는 이해되지 않는 상황들이 많았고, 끊임없이 뒤바뀌는 경기화면이 오히려 어지러움을 유발했다는 평가를 받았다. 이러한, ‘오버워치’ e-Sports 대회의 관전 문제는 결국, 게임 고정층의 충성도를 저해시키고 신규 소비자의 유입을 가로막는 걸림돌이 되었다. 지난해 8월, 게임사용 점유율 면에서 ‘오버워치’ 가 리그오브레전드(League Of Legend)를 8%나 앞서 있었다. 그럼에도 불구하고, 당시 아프리카TV가 발표한 자료에 따르면, ‘오버워치’를 방송한 BJ 시청률 상위 27명의 누적시청자 수는 207만 7554명이었다. 이 수치는, 리그오브레전드(League Of Legend)가 기록한 434만 3836명의 절반도 못 미치는 기록이었다(2017, 문대찬).
                                        <br/><br/>
                                        스포츠 중계를 시청하는 것은, 해당 스포츠에 자발적으로 참여하는데 큰 동기가 된다(2005, 이종일). 또한, 작년 4월 11일, 시장조사기관 아이리서치(iReaserch)가 발표한 ‘2016년 중국 e스포츠 콘텐츠 생태계 보고’에 따르면, e스포츠 게임을 자주 플레이 한다고 응답한 비율의 88%가 e스포츠 관련 콘텐츠를 자주 시청한다고 응답했다. 이러한 결과들을 미루어 볼 때, e-Sports 대회의 흥행은 그 종목 게임의 충성도 향상에 기여할 수 있으며, 게임 소비자 증가에 큰 영향을 준다는 것을 알 수 있다. e-Sports의 중계시스템은 시청자들의 시청 만족도에 직접적으로 관여 한다는 점에서 볼 때 결국, e-Sports에게 중계시스템은 향후 게임의 흥행을 결정지을 수 있는, 아주 중요한 요소라는 것을 확인 할 수 있다.
                                        <br/><br/>
                                        이렇게 지금까지 ‘옵서버’ 시스템의 특징에 대해서 알아보았다. 또한, 앞으로 ‘배틀그라운드’뿐만이 아니라 모든 우리나라 게임들에게, ‘옵서버’ 시스템의 문제는 세계 e-Sports 산업으로 진출하기 위해 반드시 넘어야 할 산이라는 것을 ‘오버워치’의 정체(停滯)를 통해 확인할 수 있었다. 필자는 이러한 e-Sports 미디어의 문제점을 헤쳐나가기 위해서, 현대 ‘소비자 트렌드(Trend)’에 주목할 필요성이 있다고 생각한다. 시대의 흐름에 따라 바뀌는 소비자 트렌드는, 국내 모든 소비자의 구매 활동에 직접적인 영향을 끼친다. 그리고, e-Sports의 소비자 또한 ‘소비자 트렌드’의 영향으로부터 자유로울 수 없다. 그렇기 때문에 지금부터 필자는, ‘현대 소비자 트렌드(Trend)’의 분석을 통하여 앞으로 우리나라 e-Sports 미디어가 나아가야 할 방향에 대해서 힌트를 얻어 보고자 한다.
                                        </p>
                                        <div className="project-sub-title">1. 현대 ‘소비자 트렌드’를 통해 분석한 e-Sports 미디어의 문제점</div>
                                        <p>
                                        필자가 생각하는 현대 중요한 ‘소비자 트렌드’는 두 가지가 있다. 하나는 정보 과잉 사회에서 나타나는 트렌드이고, 다른 하나는 밀레니얼(Millennials) 세대에서 나타나는 것이다. 그리고 이 두 가지의 트렌드에서 발견되는 e-Sports 미디어가 개선해야 할 부분에 대해 말해보고자 한다.
                                        </p>
                                        <div className="project-small-title">1) 정보 과잉 사회에서의 ‘소비자 트렌드’</div>
                                        <p>
                                        몇 년 전부터, 우리나라에서는 ‘결정 장애’라는 말이 유행하기 시작했다. 어학 사전에따르면,‘결정장애’는 어떠한 선택을 해야 하는 상황에서, 결정을 내리는 데 느끼는 어려운 정도가 심한 것을 말한다. 이 단어는, 현재 우리나라가 처한‘정보 과잉’ 사회를 상징하는 대표적인 단어이다. 누구나 한 번쯤은 여러 가지 선택지를 앞에 두고, 긴 시간 동안 고민에 빠져본 적이 있을 것이다. 애초에 소비자들의 선택을 돕기 위해 만든 다양한 선택지들이 오히려 소비자들의 결정을 가로막고 있는 것이다. 현대 정보사회에서 ‘결정장애’의 원인에는 여러 개인적인 요인들도 있지만, 가장 큰 원인은 제공되는 정보의 질과 내용이 모두 비슷비슷 하다는 문제점에 있다.
                                        <br/><br/>
                                        우리는 지금 정보의 홍수 속에서 살고 있다. 인터넷과 SNS(Social Network System)를 통해 엄청난 양의 정보들이 끊임없이 교류되고, 똑같은 내용의 정보들이 많은 사람에 의해 반복적으로 생성된다. 정보들에 광고가 붙고, 조회 수만으로 돈을 가져다 주기 시작하자, 순수하게 지식을 전달했던 정보들이 변질되기 시작했다. 조회 수를 위해 내용과 관련 없는 자극적인 제목이 붙거나, 영양가 없는 자극적인 내용을 전달하는 정보들이 우후죽순 생겨났다. 이러다 보니 소비자들에게 전달되는 정보의 양 자체는 전과 비교할 수 없을 정도로 많아졌지만, 그 안에 알맹이가 없다는 점에서 오히려 소비자들을 지치게 하고 있다(2017, 구현화).
                                        <br/><br/>
                                        정보의 양은 많아졌지만 소비자들이 진정으로 원하는 정보를 찾는 것이 더욱 어려워지자, 정보들을 선별하여 수집하고 여기에 새로운 가치를 부여하여 소비자들에게 전달해 주는 ‘큐레이션(Curation)’ 서비스에 대한 소비자들의 요구가 급증하고 있다(2016, 조남욱). 이전까지는 쇼핑몰이 단지 최대한 많은 종류의 제품들을 소비자들 앞에 나열했다면, 이제는 각 소비자의 취향에 맞는 제품들을 따로 선별하여 제공하여, 소비자가 최선의 선택을 할 수 있도록 도와주어야 한다.
                                        <br/><br/>
                                        이러한 소비자들의 소비 경향은 동영상 미디어에도 여과 없이 나타난다. 지난 2014년, 온라인에서 한 걸 그룹 멤버의 동영상이 올라오면서 큰 화제가 되었었다(2016, 고승희). 일명 ‘직캠’ 이라고 불리는 이 동영상은, 모든 걸 그룹 멤버가 한 동영상에 모두 담기도록 촬영하는 것이 일반적이었던 당시, 오직 한 명의 멤버만 영상에 담는 획기적인 촬영방법이었다. 사람들의 반응은 아주 뜨거웠고, 동영상의 조회 수는 끝을 모르고 올라갔다. 당시 대중의 많은 관심을 받지 못했던 동영상 속 주인공은, 이를 계기로 엄청난 인기를 누리게 되었다. 심지어 음악차트에서 아주 낮은 순위에 머물러 있던 걸 그룹의 음악은, 각종 음악차트 순위에서 역주행하여 1위까지 오르는 기염을 토했다(2017, 윤수경). 지금 동영상 공유 사이트에는, 우리나라 모든 걸 그룹 멤버의 ‘직캠’이 있을 정도로 여전히 많은 인기를 끌고 있다.
                                        <br/><br/>
                                        이러한, ‘직캠’의 유행이 미디어에 주는 의의는 아주 크다. 먼저, ‘직캠’은 걸 그룹 동영상을 시청하는 소비자들에게 ‘큐레이터’의 역할을 대신해냈다. 일반적인 동영상들은 적게는 5명, 많게는 10명이 넘는 모든 걸 그룹 멤버를 보여주며, 소비자들에게 양적인 정보만을 전달했다. 반면에 ‘직캠’은 소비자 맞춤형 서비스와도 같다. 한 멤버만 보고 싶은 소비자의 욕구에 정확히 상응하는 정보만을 담아냈으며, 소비자에게 불필요한 정보는 모두 제거함으로써 영상에 새로운 의미를 부여했다. 아주 사소한 것 같지만, 최대한 많은 정보를 시청자들에게 전달해 줘야 한다는 일반적인 생각에서 벗어나서, 카메라 렌즈에 담는 정보의 양을 줄인 것이, 오히려 엄청난 파급효과를 발생시킨 것이다.
                                        </p>
                                        <div className="img-caption-container">
                                            <img src={pic2} alt="pic2" />
                                            <div className="img-caption">[사진 2. 걸 그룹 멤버 모두를 촬영한 일반적인 영상의 모습과 한 명의 멤버만을 담아낸 ‘직캠’ 영상의 모습. ][출처. BNT International news Korea]</div>
                                        </div>
                                        <p>
                                        그렇다면, 이쯤에서 다시 한번 e-Sports ‘옵서버’ 시스템을 되돌아보자. 과연, 현재 e-Sports ‘옵서버’ 시스템이 현대 소비자의 욕구를 제대로 반영하고 있느냐고 묻는다면 필자의 대답은 ‘아니요’이다. e-Sports ‘옵서버’는 아직도 소비자들에게 많은 종류의 물품들을 그저 나열하고 선택하기를 강요하고 있다. 각 소비자가 원하는 정보가 무엇인지 선별하여 맞춤 제공하는‘큐레이션’ 서비스가 전혀 없는 것이다.
                                        <br/><br/>
                                        현재 e-Sports 대회가 활발히 진행되고 있는, 리그오브레전드(League of Legend)와 오버워치(over watch)를 예를 들어보자. 두 게임 모두, 상대 팀 캐릭터를 여러 번 처치해야만 승리를 따낼 수 있는 게임이다. 그렇기 때문에, ‘옵서버’는 철저히 캐릭터들의‘처지 장면’만을 위주로 방송을 하게 된다. 그러다 보니, 자연스럽게 선수들이 게임에서 어떠한 방법으로 자신의 캐릭터를 성장시켰는지, 적의 눈에 띄지 않고 매복 장소로 가기 위해 어떠한 루트를 이용했는지 와 같은, ‘과정’에 대한 소비자들의 궁금증은 외면당하고 있는 것이다. 소비자들은 그저 ‘처치’라는 결과에 대한 정보만을 제공받고 있다.
                                        <br/><br/>
                                        하지만, 중요한 점은 이러한 ‘과정’과 관련된 정보에 대한 소비자들의 요구가 계속해서 드러나고 있다는 것이다. 대표적인 예로 현재 리그오브레전드(League Of Legend) 게임에서 세계 최고의 자리에 올라와 있는,‘페이커’ 선수의 CS 수급 능력에 대한 소비자들의 궁금증이 있다. 리그오브레전드(League Of Legend) 게임에서 CS(Creep Score)란, 게임 중 중립 몬스터를 처치한 숫자를 의미한다. 중립 몬스터를 처치하면 캐릭터는 그에 맞는 보상을 받기 때문에, 리그오브레전드(League Of Legend) 게임에서 CS 수급 능력은 게임의 결과를 좌지우지 할 수 있는, 아주 중요한 요소이다. 그런데, ‘페이커’ 선수는 경기마다 상식적으로 이해할 수 없는 높은 CS 숫자를 기록하며 경기를 승리로 이끌었는데, 한때‘페이커 선수는 CS를 만들어 먹는다’라는 우스갯소리가 나돌 정도였다. 하지만 결과만을 보여주는 e-Sports 중계에서는 이러한 궁금증의 해답을 찾을 수 없었기 때문에, 팬들의 궁금증은 ‘페이커’ 선수의 개인방송 시청으로 직결되었다.
                                        <br/><br/>
                                        올해 초에 진행되었던 페이커 선수 개인방송의 동시 시청자 수는 무려 24만 3375명에 달했고, 누적 시청자는 총 115만 5477명을 기록하였다(2017, 김정희). 사실, ‘페이커’  선수는 유일한 약점이 ‘유머’라는 말이 있을 정도로, 유쾌한 성격의 소유자는 아니다. 이러한 선수의 성격은 개인방송에서도 그대로 드러나는데, 재치 있는 말솜씨와 끼로 시청자들의 호응을 끌어내는 다른 선수들과 달리, ‘페이커’선수는 개인방송을 진행하면서 정말 오로지‘게임’만을 한다. 그런데도 ‘페이커’선수가 엄청난 숫자의 시청자 수를 기록하는 이유는 역시 e-Sports 대회에서‘페이커’선수가 아무렇지 않게 만들어내는 엄청난 결과물들에 대한 ‘과정’이 궁금하기 때문이다. 결국, 현재 e-Sports 미디어는 이러한 ‘과정’에 대한 소비자들의 요구를 만족시키지 못하고 있으며, 다른 미디어로 이러한 잠재 소비자들을 유출시키고 있는 것이다.
                                        </p>
                                        <div className="project-small-title">2) 밀레니얼(Millennials) 세대의 ‘소비자 트렌드’</div>
                                        <p>
                                        밀레니얼(Millennials) 세대란 1980년대부터 2000년 초반에 출생한 세대를 의미하며, 정보기술(IT)에 익숙하고 능통하다는 특징을 가지고 있다. 현재 세계 스포츠 산업은 밀레니얼 세대의 등장으로 인해 많은 변화를 겪고 있다. 특히 TV를 시청하는 비중이 기성세대에 비해 현저하게 낮다는 특징 때문에, 스포츠 중계 프로그램의 시청률이 지속해서 떨어지고 있고 이와 관련된 매출 또한 함께 줄어들고 있는 상황이다. 실제로 지난 2년간 미국 스포츠 콘텐츠의 TV 시청률은 크게 감소하였다. 미국 스포츠 방송 ESPN에 따르면 미식 프로풋볼 리그(NFL)의 정기 시즌 시청자는 17%나 감소했고, 북미 아이스하키 리그(NHL)는 11%나 감소했다. 미국프로농구(NBA)와 메이저리그(MLB) 또한 8%, 11% 각각 줄어들었다. 하지만 이렇게 줄어든 시청자들이 완전히 스포츠 문화를 떠나버린 것은 아니다. 이들은 다양한 플랫폼을 통해서 경기 요약 영상이나 하이라이트 영상 등 짧은 콘텐츠를 소비한다. 스포츠문화를 자신들만의 새로운 방식으로 소비하고 있는 것이다. 하지만 스포츠의 경우, 이미 결과가 알려진 경기를 보려는 팬들은 비교적 적다는 특징 때문에, 생방송 시청자의 수는 스포츠산업의 매출에 아주 큰 영향을 준다. 그렇기 때문에, 이러한 밀레니얼 세대의 소비 형태는 장차 스포츠산업 발전에 큰 걸림돌이 될 수 있는 것이다(2017, 배정원).
                                        <br/><br/>
                                        우리가 주목해야 할 점은 바로, e-Sports 시청자의 주 연령층이 밀레니얼 세대에 모두 정확하게 포함되는, 24에서 27세라는 것이다. e-Sports 팬 중 밀레니얼 세대의 비중은 미국 4대 스포츠보다 무려 2~3배나 많다는 조사가 발표되기도 하였다(2017, 조진호). 그렇기 때문에, 앞으로 e-Sports가 밀레니얼 세대의 새로운 소비 형태에 의해 받을 영향은, 그 어떠한 스포츠보다 크다고 볼 수 있다. 매년 괄목할만한 성장을 하고 있는 e-Sports산업이 지속해서 탄력을 받기 위해서는, 이러한 밀레니얼 세대를 하이라이트 영상뿐만 아니라 생방송 앞으로도 끌어 올 수 있어야 한다. 즉, 다른 전통 스포츠 중계와는 차별화된 e-Sports만의 중계방송이 필요하다는 것이다. 가장 좋은 방법은 하이라이트 영상에서는 찾아볼 수 없는 정보를 생방송에 포함시키는 것이다.
                                        하지만, 현재 e-Sports 미디어를 다른 전통 스포츠 미디어와 비교해 볼 때, e-sports 미디어만이 가지고 있는 특별한 점은 찾아보기 힘들다. 아직도, 순전히 경기 내용만을 소비자들에게 전달하는 데 집중하기 때문이다.
                                        <br/><br/>
                                        지금까지, 두 가지의 소비자 트렌드를 통해 현재 e-Sports 미디어가 가지고 있는 문제점들을 살펴보았다. 요약하자면, e-Sports 미디어는, 현재 e-Sports 산업에서 지속해서 나타나고 있는 ‘과정’에 대한 소비자들의 요구를 채워 줄 수 있는 큐레이션 서비스가 필요하다. 또한, e-Sports의 주 소비자인 밀레니얼 세대를 사로잡기 위한, 기존 전통 스포츠 미디어와는 다른 e-sports 미디어만의 차별화된 전략이 필요하다. 필자는 이 두 가지의 문제점에 대한 해답을 ‘1인칭 시점 중계’서비스 기능에서 찾고자 한다.
                                        </p>
                                        <div className="project-sub-title">2. 현대 ‘소비자 트렌드’를 반영하기 위한, e-Sports 미디어 개선책 – 1인칭 시점 중계 서비스 기능</div>
                                        <p>
                                        필자는 앞으로 e-Sports 미디어의 문제점을 해결하고 더욱 발전하기 위해, 현재 공연 관련 동영상에서 활발히 이용되고 있는 ‘멀티트랙’기술을 주의 깊게 볼 필요가 있다고 생각한다. ‘멀티트랙’이란 동영상이 재생되는 동안 오디오와 영상의 끊김 없이 다른 화면의 앵글로 넘어갈 수 있도록 구현된 인터랙션 영상을 의미한다(2017, 김형우). 우리나라에선 걸 그룹이나 공연을 홍보하기 위해 자주 쓰이는데, ‘멀티트랙’으로 만들어진 동영상은 시청자가 선택에 따라 전체적인 모습을 볼 수도, 한 부분을 자세히 볼 수도 있다. ‘멀티트랙’으로 만들어진 걸 그룹 동영상은, 시청자가 원하는 걸 그룹 멤버를 수시로 바꿔 가며 1인칭 시점에서 볼 수 있기 때문에, ‘직캠’의 진화 버전이라고 불리기도 한다. 이러한 ‘멀티트랙’의 기능이 앞으로 e-Sports 미디어가 나아가야 할 방향이라고 생각한다. 전체적인 경기 내용을 보여주는 현재 e-Sports ‘옵서버’ 시스템을 유지하되, 소비자들의 선택에 따라 제공하는 정보가 다른, 추가적인 화면을 볼 수 있도록 하는 기능을 추가하는 것이다.
                                        </p>
                                        <div className="img-caption-container">
                                            <img src={pic3} alt="pic3" />
                                            <div className="img-caption">[사진 3. ‘멀티트랙’으로 만들어진 걸 그룹의 동영상. 왼쪽에 나와 있는 인물 목록을 선택하면 선택된 인물의 1인칭 시점으로 동영상의 끊김 없이 수시로 이동할 수 있다.][출처http://stv.seoul.co.kr/news/newsView.php?id=20150307500132]</div>
                                        </div>
                                        <p>
                                        당장, e-Sports 미디어가 이러한 ‘멀티트랙’ 기술을 중계시스템에 적용하는 것은 불가능하기 때문에, 필자는 e-Sports 미디어가 대회를 중계할 때 주요 선수들의 1인칭 시점 경기 모습도 함께 중계하는 것을 제안하고자 한다. 왜냐하면, PC로 e-Sports 방송을 시청하는 시청자들은 일반 중계 창과 1인칭 시점 중계 창을 동시에 열 수 있어 마치, ‘멀티트랙’기술이 적용된 e-Sports 중계방송을 보는 것 같은 효과를 볼 수 있기 때문이다. 스마트폰을 이용하는 시청자도, 수시로 중계 채널을 바꾸어 대회를 다양한 시점으로 시청할 수 있게 된다.
                                        <br/><br/>
                                        ‘1인칭 시점 중계 서비스’는 앞서 필자가 소비자 트렌드를 통해 분석한 e-Sports 미디어의 문제점들을 해결할 수 있다. 먼저 지속해서 나타났던 소비자들의 ‘과정’에 대한 욕구를 충족시켜 줌으로써, 개인방송 시청자들을 e-Sports 시청자로 유인할 수 있다. 앞에서 언급했듯이, 많은 수의 개인방송 시청자들이 선수들의 개인방송을 보는 이유는, e-Sports 선수들의 1인칭 시점을 보기 위해서이기 때문이다. 그들이 좋아하는 선수가, 어떠한 방법으로 캐릭터를 성장시키는지, 어떠한 근거로 결정 판단을 내리는지와 같은 ‘과정’에 관한 정보들은 1인칭 시점에서만 찾을 수 있는 정보들이기 때문이다.
                                        <br/><br/>
                                        또한, ‘1인칭 시점 중계’ 서비스는 다른 전통 스포츠에는 없는, e-Sports만이 도입 가능한 독보적인 서비스라는 측면에서, 다른 스포츠 미디어와 확실한 차별화가 가능하게 된다. 이러한 차별점은 시청자들이 재미뿐만 아니라 선수의 성향 분석, 전략 분석 등 여러 목적을 가지고 e-Sports를 시청하는 것을 가능하도록 만든다. 또한, 이러한 정보들은 무엇보다 하이라이트 영상에서는 찾아볼 수 없는 정보라는 점에서, 현재 e-Sports의 주 시청자인 밀레니얼 세대를, 생방송 중계 앞으로 끌어 올 수 있다.
                                        </p>
                                        <div className="project-sub-title">3.글을 맺으며</div>
                                        <p>
                                        지금까지 현대 ‘소비자 트렌드’를 통해 앞으로 e-Sports 미디어가 나아가야 할 방향에 대해 살펴보았고, 문제점을 극복하기 위한 개선책도 제시해 보았다. 필자가 소개한 ‘멀티트랙’기술과, 제안한‘1인칭 시점 중계 서비스’도입은 앞으로 우리나라 e-Sports 산업이 관전 시스템에서 나타나는 문제점을 극복해 내는데, 충분히 고려해 봐야 할 부분이라고 생각한다.
                                        <br/><br/>
                                        스포츠 산업의 역사는 항상 중계기술의 발전과 함께하였다. 방송사들은 시청률을 끌어올리기 위해, 어떻게든 시청자들에게 생생한 현장감을 전달하고자 노력하였다. 그리고 이러한 노력은 현재진행형이다. 미국 프로야구(MLB)는 투구와 타구의 방향과 속도를 실시간으로 추적 가능한 고급 군사 기술을 이용하는가 하면, 축구리그들은 미사일 추적 기술을 응용하여 선수들의 다양한 정보를 중계하고 있다. 또한, 현재 가상현실(VR)서비스와 인공지능(AI)기능을 스포츠 중계에 접목시키는 연구들이 활발하게 시도되고 있다(2016, 윤은용). 올해 천문학적인 중계권료를 기록한 영국 프로 축구 리그(EPL)의 바탕에도 전 세계 팬들을 사로잡은 EPL만의 독보적인 첨단 중계기술이 있었다. e-Sports도 예외가 되어서는 안 된다. 앞서 살펴보았듯이 중계 시스템은 e-Sports 대회의 흥행과 직결되는 문제이며, e-Sports 대회의 흥행은 그 종목 게임의 충성도 향상과 소비자 증가에 기여한다. 결국, e-Sports 중계 시스템 또한 e-Sports의 괄목한 성장과 함께 지속해서 발전해야 하며, 현대 소비자들의 요구에 맞추어 끊임없이 변화해야 한다.
                                        <br/><br/>
                                        앞으로 게임 ‘배틀그라운드’가, 현재 처해 있는 중계 시스템의 문제를 슬기롭게 헤쳐 나가, 국산 게임들이 세계 e-Sports 산업으로 도약할 수 있는 활로를 열어주기를 바란다.
                                        </p>

                                        <div className="project-sub-title">References</div>
                                        <p>
                                        김정희(2017년 5월 22일). 호나우두가 인정한 ‘페이커’ 이상혁, 개인방송 시작 ‘백만 명 훌쩍’. 한국스포츠경제. Retrieved October 29,2017 from
                                        <br/><br/>
                                        http://stv.seoul.co.kr/news/newsView.php?id=20170526500060&wlog_tag3=naver
                                        <br/><br/>
                                        김형우(2017년 5월 26일). [멀티트랙]크리샤 츄’트러블’쇼케이스무대. 서울TV. Retrieved October 29,2017 from
                                        <br/><br/>
                                        http://www.sporbiz.co.kr/news/articleView.html?idxno=107610
                                        <br/><br/>
                                        고승희(2016년 6월 5일). 역주행 신화 EXID 하니 직캠 2000만뷰 돌파..”벼랑 끝에 내려온 동아줄”. 헤럴드경제. Retrieved November 5,2017 from
                                        <br/><br/>
                                        http://news.heraldcorp.com/view.php?ud=20160605000253
                                        <br/><br/>
                                        구현화(2017년 10월 14일). [기자수첩]큐레이션의 시대. 쿠키뉴스. Retrieved November 5,2017 from
                                        <br/><br/>
                                        http://www.kukinews.com/news/article.html?no=491436
                                        <br/><br/>
                                        노성열(2017년 9월 28일). 스마트폰으로 월평균 12시간 동영상 본다.”. 문화일보. Retrieved November 5,2017 from
                                        <br/><br/>
                                        http://www.munhwa.com/news/view.html?no=2017092801033503009001
                                        <br/><br/>
                                        박범(2017년 10월 6일). 롤드컵, 스포츠를 꿈꾸다”. 네이버 스포츠. Retrieved November 5,2017 from
                                        <br/><br/>
                                        http://sports.news.naver.com/esports/news/read.nhn?oid=064&aid=0000005326
                                        <br/><br/>
                                        박준영(2017년 11월 3일). 배틀그라운드, e스포츠화 ‘속도’….팀 창단 러시. 아이뉴스. Retrieved November 5,2017 from
                                        <br/><br/>
                                        http://news.inews24.com/php/news_view.php?g_serial=1058125&g_menu=020534&rrf=nv
                                        <br/><br/>
                                        배정원(2017년 11월 7일). “아이 있는 여성 공략해야” 디지털 시대의 스포츠 산업. 이코노미조선. Retrieved November 5,2017 from
                                        <br/><br/>
                                        http://biz.chosun.com/site/data/html_dir/2017/11/09/2017110901740.html
                                        <br/><br/>
                                        서삼광(2017년 10월 31일). 리그오브레전드, pc방 연속 1위, ‘배틀그라운드’추격 따돌려. 베타뉴스. Retrieved November 5,2017 from
                                        <br/><br/>
                                        http://betanews.heraldcorp.com:8080/article/759452.html
                                        <br/><br/>
                                        서영준(2017년 10월 23일). 외산 일색 e-스포츠, 국산이 주도한다. 파이낸셜뉴스. Retrieved November 5,2017 from
                                        <br/><br/>
                                        http://www.fnnews.com/news/201710231352562136
                                        <br/><br/>
                                        이종일 (2005). 스포츠 참여와 TV스포츠 중계프로그램 시청의 관계. 경희대학교 테크노경영대학원, 석사학위논문, 서울.
                                        <br/><br/>
                                        원태영(2017년 7월 14일). 위기의 오버워치, 점유율 하락 언제까지. 시사저널. Retrieved November 5,2017 from
                                        <br/><br/>
                                        http://www.sisajournal-e.com/biz/article/171442
                                        <br/><br/>
                                        윤민섭(2017년 10월 24일). 인비테이셔널이 남긴 배틀 그라운드 e스포츠화 숙제. 쿠키뉴스. Retrieved November 5,2017 from
                                        <br/><br/>
                                        http://www.kukinews.com/news/article.html?no=494518
                                        <br/><br/>
                                        윤수경(2017년 2월 24일). 역주행도 거뜬! 레전드로 남을 女 아이돌 ‘인생 직캠’. 스포츠서울. Retrieved November 5,2017 from
                                        <br/><br/>
                                        http://www.sportsseoul.com/news/read/537484
                                        <br/><br/>
                                        조남욱(2016년 10월 27일). 정보과잉에 지친 소비자 위한 취향저격 큐레이션 마케팅이란?. 데일리그리드. Retrieved November 5,2017 from
                                        <br/><br/>
                                        http://www.dailygrid.net/news/articleView.html?idxno=43665
                                        <br/><br/>
                                        조진호(2017년 7월 19일). e스포츠 플랫폼 경쟁 점화… 아이덴티티, 새 브랜드’WEGL’출범. 스포츠경향. Retrieved November 5,2017 from
                                        <br/><br/>
                                        http://sports.khan.co.kr/bizlife/sk_index.html?art_id=201707191659003&sec_id=560201&pt=nv
                                        <br/><br/>
                                        최상현(2017년 10월 30일). “1800만장 이미 흥행,,,’배틀그라운드’로 돌풍 예고”. 헤럴드경제. Retrieved November 5,2017 from
                                        <br/><br/>
                                        http://news.heraldcorp.com/view.php?ud=20171030000531
                                        <br/><br/>
                                        문대찬(2017년 2월 24일). [기획]블리자드 오버워치 리그, ‘그들만의 리그’ 될까. 쿠키뉴스. Retrieved November 5,2017 from
                                        <br/><br/>
                                        http://www.kukinews.com/news/article.html?no=434848
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="biarrow-container">
                                <div className="arrow-container">
                                    <div className="arrow-box">
                                        <LinkStyleCom to="/WorkScreenETC" style={{textDecoration:"none"}}>BACK TO ETC</LinkStyleCom>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </WorkDetailETCStyleCom>
                    </motion.div>
        </>
    )
}

export default WorkDetailETC2
