import React from 'react'

function FormContainer({children}) {

    return (
        <>
            {children}
            
        </>
    )
}

export default FormContainer
