export const RECORD_LIST_REQUEST = 'RECORD_LIST_REQUEST'
export const RECORD_LIST_SUCCESS = 'RECORD_LIST_SUCCESS'
export const RECORD_LIST_FAIL = 'RECORD_LIST_FAIL'

export const RECORD_DETAILS_REQUEST = 'RECORD_DETAILS_REQUEST'
export const RECORD_DETAILS_SUCCESS = 'RECORD_DETAILS_SUCCESS'
export const RECORD_DETAILS_FAIL = 'RECORD_DETAILS_FAIL'

export const RECORD_DELETE_REQUEST = 'RECORD_DELETE_REQUEST'
export const RECORD_DELETE_SUCCESS = 'RECORD_DELETE_SUCCESS'
export const RECORD_DELETE_FAIL = 'RECORD_DELETE_FAIL'

export const RECORD_CREATE_REQUEST = 'RECORD_CREATE_REQUEST'
export const RECORD_CREATE_SUCCESS = 'RECORD_CREATE_SUCCESS'
export const RECORD_CREATE_FAIL = 'RECORD_CREATE_FAIL'
export const RECORD_CREATE_RESET = 'RECORD_CREATE_RESET'

export const RECORD_UPDATE_REQUEST = 'RECORD_UPDATE_REQUEST'
export const RECORD_UPDATE_SUCCESS = 'RECORD_UPDATE_SUCCESS'
export const RECORD_UPDATE_FAIL = 'RECORD_UPDATE_FAIL'
export const RECORD_UPDATE_RESET = 'RECORD_UPDATE_RESET'