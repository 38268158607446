import { Link } from 'react-router-dom'
import styled from "styled-components"

export const LinkStyleCom = styled(Link)`

    // margin: "1rem",
    /* font-family: 'Roboto'; */
    text-decoration: "none";
    /* color:blue; */

    /* &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    } */
     
    // color: 'blue'
`;