import React from 'react'

function WorkDetailWEB() {
    return (
        <>
            workdetailWEB   
        </>
    )
}

export default WorkDetailWEB