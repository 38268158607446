import styled from "styled-components"

export const BannerStyleCom = styled.div`
    
    .banner-container{
        margin-top: 3%;
        height: 300vh;
        /* position: relative; */
        max-width: 1500px;
        /* height: calc(var(--vh, 1vh) * 79);  */
        width: 100%;
        /* margin-top: 16%; */
        background-color: blue;
        /* opacity:0; */
        color: white;
    }
`