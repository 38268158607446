import styled from "styled-components"

export const TransitionStyleCom = styled.div`

    .transition-effect{
        /* position:absolute; */
        position:fixed;
        z-index: 99999999999999;
        background-color: darkgray;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .transition-effect2{
        /* position:absolute; */
        position:fixed;
        z-index: 99999999999999;
        /* background-color: ghostwhite; */
        background-color: #161616;
        top: 0;
        width: 100%;
        height: 100%;
    }


`