
const countryPoint = [

  // South America
  
  // {
  //   '_id': 1,
  //   'name': 'Galapagos Island',
  //   'url' : '/Galapagos', 
  //   'lat': '-0.8589567103236243',
  //   'lon': '-90.9444350902189',
  //   'latTransit': '0',
  //   'lonTransit': '0',
  //   'x' : '0',
  //   'y' : '0',
  //   'z' : '0',
  //   'message' : '',
  //   'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/galapagosMain.jpg",
  //   'imgList' : [
  //     { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/1170/galapagos.jpg" },
  //     { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/1170/galapagos2.jpg" },
  //     { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/1170/galapagos3.jpg" },
  //     { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/1170/galapagos4.jpg" },
  //     { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/1170/galapagos5.jpg" },
  //     { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/1170/galapagos6.jpg" },
  //     { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/1170/galapagos7.jpg" },
  //     { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/1170/galapagos8.jpg" },
  //     { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/1170/galapagos9.jpg" },
  //     { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/1170/galapagos10.jpg" },
  //     { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/1170/galapagos11.jpg" },
  //     { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/1170/galapagos12.jpg" },
  //     { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/1170/galapagos13.jpg" },
  //     { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/1170/galapagos14.jpg" },
  //     { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/1170/galapagos15.jpg" },
  //     { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/1170/galapagos16.jpg" },
  //   ],
  //   'simgList' : [
  //     { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/300/galapagos.jpg" },
  //     { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/300/galapagos2.jpg" },
  //     { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/300/galapagos3.jpg" },
  //     { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/300/galapagos4.jpg" },
  //     { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/300/galapagos5.jpg" },
  //     { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/300/galapagos6.jpg" },
  //     { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/300/galapagos7.jpg" },
  //     { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/300/galapagos8.jpg" },
  //     { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/300/galapagos9.jpg" },
  //     { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/300/galapagos10.jpg" },
  //     { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/300/galapagos11.jpg" },
  //     { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/300/galapagos12.jpg" },
  //     { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/300/galapagos13.jpg" },
  //     { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/300/galapagos14.jpg" },
  //     { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/300/galapagos15.jpg" },
  //     { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/galapagos/300/galapagos16.jpg" },
  //   ],
  // }, 
    {
    '_id': 1,
    'name': 'Isabela',
    'url' : '/Isabela', 
    'color' : 0x18B4E9,
    'lat': '-0.8589567103236243',
    'lon': '-92.9444350902189',
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/IsabelaMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/1170/Isabela.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/1170/Isabela2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/1170/Isabela3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/1170/Isabela4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/1170/Isabela5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/1170/Isabela6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/1170/Isabela7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/1170/Isabela8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/1170/Isabela9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/1170/Isabela10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/1170/Isabela11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/1170/Isabela12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/1170/Isabela13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/1170/Isabela14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/1170/Isabela15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/1170/Isabela16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/1170/Isabela17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/1170/Isabela18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/1170/Isabela19.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/300/Isabela.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/300/Isabela2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/300/Isabela3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/300/Isabela4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/300/Isabela5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/300/Isabela6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/300/Isabela7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/300/Isabela8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/300/Isabela9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/300/Isabela10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/300/Isabela11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/300/Isabela12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/300/Isabela13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/300/Isabela14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/300/Isabela15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/300/Isabela16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/300/Isabela17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/300/Isabela18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Isabela/300/Isabela19.jpg" },
    ],
  },
  {
    '_id': 2,
    'name': 'SanCristobal',
    'url' : '/SanCristobal', 
    'color' : 0xffffff,
    'lat': '-0.9589567103236243',
    'lon': '-89.9444350902189',
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/SanCristobalMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal19.jpg" },
      { id: 20, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal20.jpg" },
      { id: 21, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal21.jpg" },
      { id: 22, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal22.jpg" },
      { id: 23, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal23.jpg" },
      { id: 24, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal24.jpg" },
      { id: 25, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal25.jpg" },
      { id: 26, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/1170/SanCristobal26.jpg" },
      ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal19.jpg" },
      { id: 20, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal20.jpg" },
      { id: 21, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal21.jpg" },
      { id: 22, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal22.jpg" },
      { id: 23, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal23.jpg" },
      { id: 24, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal24.jpg" },
      { id: 25, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal25.jpg" },
      { id: 26, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SanCristobal/300/SanCristobal26.jpg" },
    ],
  },
  {
    '_id': 3,
    'name': 'SantaCruz',
    'url' : '/SantaCruz', 
    'color' : 0xffffff,
    'lat': '-0.1989567103236243',
    'lon': '-91.2444350902189',
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/SantaCruzMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/1170/SantaCruz.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/1170/SantaCruz2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/1170/SantaCruz3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/1170/SantaCruz4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/1170/SantaCruz5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/1170/SantaCruz6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/1170/SantaCruz7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/1170/SantaCruz8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/1170/SantaCruz9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/1170/SantaCruz10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/1170/SantaCruz11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/1170/SantaCruz12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/1170/SantaCruz13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/1170/SantaCruz14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/1170/SantaCruz15.jpg" },
      ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/300/SantaCruz.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/300/SantaCruz2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/300/SantaCruz3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/300/SantaCruz4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/300/SantaCruz5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/300/SantaCruz6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/300/SantaCruz7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/300/SantaCruz8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/300/SantaCruz9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/300/SantaCruz10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/300/SantaCruz11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/300/SantaCruz12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/300/SantaCruz13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/300/SantaCruz14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/SantaCruz/300/SantaCruz15.jpg" },
      ],
  },
  {
    '_id': 4,
    'name': 'GSantiago',
    'url' : '/GSantiago', 
    'color' : 0xffffff,
    'lat': '-1.6589567103236243',
    'lon': '-91.3444350902189',
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/SantiagoMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/1170/Santiago.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/1170/Santiago2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/1170/Santiago3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/1170/Santiago4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/1170/Santiago5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/1170/Santiago6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/1170/Santiago7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/1170/Santiago8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/1170/Santiago9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/1170/Santiago10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/1170/Santiago11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/1170/Santiago12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/1170/Santiago13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/1170/Santiago14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/1170/Santiago15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/1170/Santiago16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/1170/Santiago17.jpg" },
      ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/300/Santiago.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/300/Santiago2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/300/Santiago3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/300/Santiago4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/300/Santiago5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/300/Santiago6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/300/Santiago7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/300/Santiago8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/300/Santiago9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/300/Santiago10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/300/Santiago11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/300/Santiago12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/300/Santiago13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/300/Santiago14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/300/Santiago15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/300/Santiago16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/GSantiago/300/Santiago17.jpg" },
      ],
  },
  
  {
    '_id': 5,
    'name': 'Calafate',
    'url' : '/Calafate',
    'color' : 0xffffff,
    'lat': '-50.588270',
    'lon': '-73.136688',
    'latTransit': '0', 
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCalafate/CalafateMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCalafate/1170/Calafate.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCalafate/1170/Calafate2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCalafate/1170/Calafate3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCalafate/1170/Calafate4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCalafate/1170/Calafate5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCalafate/1170/Calafate6.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCalafate/300/Calafate.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCalafate/300/Calafate2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCalafate/300/Calafate3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCalafate/300/Calafate4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCalafate/300/Calafate5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCalafate/300/Calafate6.jpg" },
    ],
  },
  {
    '_id': 6,
    'name': 'Del Paine',
    'url' : '/Toress',
    'color' : 0x16aa52,
    'lat': '-51.99245927487738',
    'lon': '-73.42359254229844',
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/toressMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/1170/toress.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/1170/toress2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/1170/toress3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/1170/toress4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/1170/toress5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/1170/toress6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/1170/toress7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/1170/toress8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/1170/toress9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/1170/toress10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/1170/toress11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/1170/toress12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/1170/toress13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/1170/toress14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/1170/toress15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/1170/toress16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/1170/toress17.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/300/toress.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/300/toress2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/300/toress3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/300/toress4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/300/toress5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/300/toress6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/300/toress7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/300/toress8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/300/toress9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/300/toress10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/300/toress11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/300/toress12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/300/toress13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/300/toress14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/300/toress15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/300/toress16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Toress/300/toress17.jpg" },
    ],
  },
  {
    '_id': 7,
    'name': 'Charten',
    'url' : '/Charten',
    'color' : 0xffffff,
    'lat': '-49.32581537220871',
    'lon': '-72.89049656522988',
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCharten/ElChartenMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCharten/1170/ElCharten.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCharten/1170/ElCharten2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCharten/1170/ElCharten3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCharten/1170/ElCharten4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCharten/1170/ElCharten5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCharten/1170/ElCharten6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCharten/1170/ElCharten7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCharten/1170/ElCharten8.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCharten/300/ElCharten.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCharten/300/ElCharten2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCharten/300/ElCharten3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCharten/300/ElCharten4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCharten/300/ElCharten5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCharten/300/ElCharten6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCharten/300/ElCharten7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/ElCharten/300/ElCharten8.jpg" },
    ],
  },
  { 
    '_id': 8,
    'name': 'Copacabana',
    'url' : '/Copacabana',
    'color' : 0xFEE134,
    'lat': '-16.039597283211606',
    'lon': '-69.07138742272008',
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/CopacabanaMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/1170/Copacabana.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/1170/Copacabana2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/1170/Copacabana3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/1170/Copacabana4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/1170/Copacabana5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/1170/Copacabana6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/1170/Copacabana7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/1170/Copacabana8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/1170/Copacabana9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/1170/Copacabana10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/1170/Copacabana11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/1170/Copacabana12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/1170/Copacabana13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/1170/Copacabana14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/1170/Copacabana15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/1170/Copacabana16.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/300/Copacabana.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/300/Copacabana2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/300/Copacabana3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/300/Copacabana4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/300/Copacabana5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/300/Copacabana6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/300/Copacabana7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/300/Copacabana8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/300/Copacabana9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/300/Copacabana10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/300/Copacabana11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/300/Copacabana12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/300/Copacabana13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/300/Copacabana14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/300/Copacabana15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Copacabana/300/Copacabana16.jpg" },
    ],
  },
  {
    '_id': 9,
    'name': 'Uyuni',
    'url' : '/Uyuni', 
    'color' : 0xF15B5B,
    'lat': '-20.242260534756447',
    'lon': '-67.41536526696457',
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/uyuniMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/1170/uyuni.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/1170/uyuni2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/1170/uyuni3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/1170/uyuni4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/1170/uyuni5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/1170/uyuni6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/1170/uyuni7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/1170/uyuni8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/1170/uyuni9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/1170/uyuni10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/1170/uyuni11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/1170/uyuni12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/1170/uyuni13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/1170/uyuni14.jpg" },
      // { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/1170/uyuni15.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/300/uyuni.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/300/uyuni2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/300/uyuni3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/300/uyuni4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/300/uyuni5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/300/uyuni6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/300/uyuni7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/300/uyuni8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/300/uyuni9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/300/uyuni10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/300/uyuni11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/300/uyuni12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/300/uyuni13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/300/uyuni14.jpg" },
      // { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Uyuni/300/uyuni15.jpg" },
    ],
  },
  {
    '_id': 10,
    'name': 'Cusco',
    'url' : '/Cusco', 
    'color' : 0xffffff,
    'lat': '-13.518667574959322',
    'lon': '-71.9831948947368',
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Cusco/cuscoMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Cusco/1170/cusco.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Cusco/1170/cusco2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Cusco/1170/cusco3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Cusco/1170/cusco4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Cusco/1170/cusco5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Cusco/1170/cusco6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Cusco/1170/cusco7.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Cusco/300/cusco.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Cusco/300/cusco2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Cusco/300/cusco3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Cusco/300/cusco4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Cusco/300/cusco5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Cusco/300/cusco6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Cusco/300/cusco7.jpg" },
    ],
  },
  {
    '_id': 11,
    'name': 'Macchuppichu',
    'url' : '/Macchuppichu', 
    'color' : 0xC38866,
    'lat': '-12.163068062119503',
    'lon': '-72.54500581759812', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/MacchuppichuMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu19.jpg" },
      { id: 20, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu20.jpg" },
      { id: 21, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu21.jpg" },
      { id: 22, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu22.jpg" },
      { id: 23, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu23.jpg" },
      { id: 24, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu24.jpg" },
      { id: 25, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu25.jpg" },
      { id: 26, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu26.jpg" },
      { id: 27, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu27.jpg" },
      { id: 28, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu28.jpg" },
      { id: 29, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu29.jpg" },
      { id: 30, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu30.jpg" },
      { id: 31, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu31.jpg" },
      { id: 32, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu32.jpg" },
      { id: 33, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu33.jpg" },
      { id: 34, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu34.jpg" },
      { id: 35, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu35.jpg" },
      { id: 36, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu36.jpg" },
      { id: 37, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu37.jpg" },
      { id: 38, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu38.jpg" },
      { id: 39, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu39.jpg" },
      { id: 40, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu40.jpg" },
      { id: 41, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu41.jpg" },
      { id: 42, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu42.jpg" },
      { id: 43, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu43.jpg" },
      { id: 44, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu44.jpg" },
      { id: 45, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu45.jpg" },
      { id: 46, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu46.jpg" },
      { id: 47, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu47.jpg" },
      { id: 48, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu48.jpg" },
      { id: 49, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu49.jpg" },
      { id: 50, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu50.jpg" },
      { id: 51, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/1170/Macchuppichu51.jpg" },
      ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu19.jpg" },
      { id: 20, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu20.jpg" },
      { id: 21, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu21.jpg" },
      { id: 22, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu22.jpg" },
      { id: 23, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu23.jpg" },
      { id: 24, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu24.jpg" },
      { id: 25, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu25.jpg" },
      { id: 26, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu26.jpg" },
      { id: 27, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu27.jpg" },
      { id: 28, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu28.jpg" },
      { id: 29, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu29.jpg" },
      { id: 30, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu30.jpg" },
      { id: 31, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu31.jpg" },
      { id: 32, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu32.jpg" },
      { id: 33, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu33.jpg" },
      { id: 34, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu34.jpg" },
      { id: 35, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu35.jpg" },
      { id: 36, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu36.jpg" },
      { id: 37, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu37.jpg" },
      { id: 38, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu38.jpg" },
      { id: 39, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu39.jpg" },
      { id: 40, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu40.jpg" },
      { id: 41, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu41.jpg" },
      { id: 42, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu42.jpg" },
      { id: 43, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu43.jpg" },
      { id: 44, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu44.jpg" },
      { id: 45, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu45.jpg" },
      { id: 46, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu46.jpg" },
      { id: 47, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu47.jpg" },
      { id: 48, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu48.jpg" },
      { id: 49, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu49.jpg" },
      { id: 50, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu50.jpg" },
      { id: 51, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Macchuppichu/300/Macchuppichu51.jpg" },
    ],
  },
  {
    '_id': 12,
    'name': 'Lapaz',
    'url' : '/Lapaz',
    'color' : 0xffffff,
    'lat': '-17.493379292833755',
    'lon': '-68.1374694895629', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lapaz/lapazMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lapaz/1170/lapaz.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lapaz/1170/lapaz2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lapaz/1170/lapaz3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lapaz/1170/lapaz4.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lapaz/300/lapaz.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lapaz/300/lapaz2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lapaz/300/lapaz3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lapaz/300/lapaz4.jpg" },
    ],
  },
  {
    '_id': 13,
    'name': 'Santiago',
    'url' : '/Santiago', 
    'color' : 0xffffff,
    'lat': '-33.44463877831767',
    'lon': '-70.68339796923053', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Santiago/SantiagoMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Santiago/1170/Santiago2.jpg" },
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Santiago/1170/Santiago3.jpg" },
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Santiago/1170/Santiago4.jpg" },
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Santiago/1170/Santiago5.jpg" },
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Santiago/1170/Santiago6.jpg" },
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Santiago/1170/Santiago7.jpg" },
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Santiago/1170/Santiago8.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Santiago/300/Santiago2.jpg" },
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Santiago/300/Santiago3.jpg" },
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Santiago/300/Santiago4.jpg" },
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Santiago/300/Santiago5.jpg" },
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Santiago/300/Santiago6.jpg" },
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Santiago/300/Santiago7.jpg" },
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Santiago/300/Santiago8.jpg" },
    ],
  },
  {
    '_id': 14,
    'name': 'Lima',
    'url' : '/Lima', 
    'color' : 0xffffff,
    'lat': '-12.0464',
    'lon': '-77.0428', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/LimaMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima19.jpg" },
      { id: 20, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima20.jpg" },
      { id: 21, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima21.jpg" },
      { id: 22, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima22.jpg" },
      { id: 23, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima23.jpg" },
      { id: 24, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima24.jpg" },
      { id: 25, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima25.jpg" },
      { id: 26, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima26.jpg" },
      { id: 27, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima27.jpg" },
      { id: 28, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima28.jpg" },
      { id: 29, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima29.jpg" },
      { id: 30, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima30.jpg" },
      { id: 31, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/1170/Lima31.jpg" },
    ],
    'simgList' : [
    
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima19.jpg" },
      { id: 20, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima20.jpg" },
      { id: 21, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima21.jpg" },
      { id: 22, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima22.jpg" },
      { id: 23, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima23.jpg" },
      { id: 24, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima24.jpg" },
      { id: 25, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima25.jpg" },
      { id: 26, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima26.jpg" },
      { id: 27, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima27.jpg" },
      { id: 28, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima28.jpg" },
      { id: 29, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima29.jpg" },
      { id: 30, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima30.jpg" },
      { id: 31, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Lima/300/Lima31.jpg" },
    ],
  },

  {
    '_id': 15,
    'name': 'Ica',
    'url' : '/Ica', 
    'color' : 0xffffff,
    'lat': '-14.075460',
    'lon': '-75.734181', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/IcaMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/1170/Ica.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/1170/Ica2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/1170/Ica3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/1170/Ica4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/1170/Ica5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/1170/Ica6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/1170/Ica7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/1170/Ica8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/1170/Ica9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/1170/Ica10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/1170/Ica11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/1170/Ica12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/1170/Ica13.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/300/Ica.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/300/Ica2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/300/Ica3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/300/Ica4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/300/Ica5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/300/Ica6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/300/Ica7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/300/Ica8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/300/Ica9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/300/Ica10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/300/Ica11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/300/Ica12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/Ica/300/Ica13.jpg" },
    ],
  },

  {
    '_id': 16,
    'name': 'BuenosAires',
    'url' : '/BuenosAires', 
    'color' : 0xffffff,
    'lat': '-34.603722',
    'lon': '-58.381592', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/BuenosAires/BuenosAiresMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/BuenosAires/1170/BuenosAires.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/BuenosAires/1170/BuenosAires2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/BuenosAires/1170/BuenosAires3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/BuenosAires/1170/BuenosAires4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/BuenosAires/1170/BuenosAires5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/BuenosAires/1170/BuenosAires6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/BuenosAires/1170/BuenosAires7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/BuenosAires/1170/BuenosAires8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/BuenosAires/1170/BuenosAires9.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/BuenosAires/300/BuenosAires.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/BuenosAires/300/BuenosAires2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/BuenosAires/300/BuenosAires3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/BuenosAires/300/BuenosAires4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/BuenosAires/300/BuenosAires5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/BuenosAires/300/BuenosAires6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/BuenosAires/300/BuenosAires7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/BuenosAires/300/BuenosAires8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/South+America/BuenosAires/300/BuenosAires9.jpg" },
    ],
  },


  // Road Trip USA

  {
    '_id': 17,
    'name': 'Antelope',
    'url' : '/Antelope', 
    'color' : 0xC23352,
    'lat': '36.952766',
    'lon': '-111.441269', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Antelope/AntelopeMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Antelope/1170/Antelope.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Antelope/1170/Antelope2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Antelope/1170/Antelope3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Antelope/1170/Antelope4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Antelope/1170/Antelope5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Antelope/1170/Antelope6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Antelope/1170/Antelope7.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Antelope/300/Antelope.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Antelope/300/Antelope2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Antelope/300/Antelope3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Antelope/300/Antelope4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Antelope/300/Antelope5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Antelope/300/Antelope6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Antelope/300/Antelope7.jpg" },
    ],
  },

  {
    '_id': 18,
    'name': 'ArchesNP',
    'url' : '/ArchesNP', 
    'color' : 0xe16350,
    'lat': '38.733082',
    'lon': '-109.592514', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/archesMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/1170/arches.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/1170/arches2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/1170/arches3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/1170/arches4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/1170/arches5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/1170/arches6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/1170/arches7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/1170/arches8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/1170/arches9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/1170/arches10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/1170/arches11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/1170/arches12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/1170/arches13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/1170/arches14.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/300/arches.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/300/arches2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/300/arches3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/300/arches4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/300/arches5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/300/arches6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/300/arches7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/300/arches8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/300/arches9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/300/arches10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/300/arches11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/300/arches12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/300/arches13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/ArchesNP/300/arches14.jpg" },
    ],
  },

  {
    '_id': 19,
    'name': 'Atlanta',
    'url' : '/Atlanta', 
    'color' : 0xffffff,
    'lat': '33.748783',
    'lon': '-84.388168', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/AtlantaMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/1170/Atlanta.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/1170/Atlanta2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/1170/Atlanta3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/1170/Atlanta4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/1170/Atlanta5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/1170/Atlanta6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/1170/Atlanta7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/1170/Atlanta8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/1170/Atlanta9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/1170/Atlanta10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/1170/Atlanta11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/1170/Atlanta12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/1170/Atlanta13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/1170/Atlanta14.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/300/Atlanta.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/300/Atlanta2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/300/Atlanta3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/300/Atlanta4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/300/Atlanta5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/300/Atlanta6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/300/Atlanta7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/300/Atlanta8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/300/Atlanta9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/300/Atlanta10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/300/Atlanta11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/300/Atlanta12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/300/Atlanta13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Atlanta/300/Atlanta14.jpg" },
    ],
  },

  {
    '_id': 20,
    'name': 'Bortmore',
    'url' : '/Bortmore', 
    'color' : 0xffffff,
    'lat': '39.299236',
    'lon': '-76.609383', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Bortmore/BortmoreMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Bortmore/1170/BortmoreMain.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Bortmore/300/BortmoreMain.jpg" },
    ]
  },

  {
    '_id': 21,
    'name': 'Carsbad',
    'url' : '/Carsbad', 
    'color' : 0xE9967A,
    'lat': '32.417934',
    'lon': '-104.229034', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/CarsbadMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/1170/Carsbad.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/1170/Carsbad2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/1170/Carsbad3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/1170/Carsbad4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/1170/Carsbad5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/1170/Carsbad6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/1170/Carsbad7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/1170/Carsbad8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/1170/Carsbad9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/1170/Carsbad10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/1170/Carsbad11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/1170/Carsbad12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/1170/Carsbad13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/1170/Carsbad14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/1170/Carsbad15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/1170/Carsbad16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/1170/Carsbad17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/1170/Carsbad18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/1170/Carsbad19.jpg" },
      { id: 20, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/1170/Carsbad20.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/300/Carsbad.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/300/Carsbad2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/300/Carsbad3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/300/Carsbad4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/300/Carsbad5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/300/Carsbad6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/300/Carsbad7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/300/Carsbad8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/300/Carsbad9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/300/Carsbad10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/300/Carsbad11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/300/Carsbad12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/300/Carsbad13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/300/Carsbad14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/300/Carsbad15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/300/Carsbad16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/300/Carsbad17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/300/Carsbad18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/300/Carsbad19.jpg" },
      { id: 20, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/CarlsbadCavern/300/Carsbad20.jpg" },
    ]
  },

  {
    '_id': 22,
    'name': 'Chicago',
    'url' : '/Chicago', 
    'color' : 0xFFA07A,
    'lat': '41.881832',
    'lon': '-87.623177', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/ChicagoMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/1170/Chicago.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/1170/Chicago2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/1170/Chicago3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/1170/Chicago4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/1170/Chicago5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/1170/Chicago6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/1170/Chicago7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/1170/Chicago8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/1170/Chicago9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/1170/Chicago10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/1170/Chicago11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/1170/Chicago12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/1170/Chicago13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/1170/Chicago14.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/300/Chicago.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/300/Chicago2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/300/Chicago3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/300/Chicago4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/300/Chicago5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/300/Chicago6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/300/Chicago7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/300/Chicago8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/300/Chicago9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/300/Chicago10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/300/Chicago11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/300/Chicago12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/300/Chicago13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Chicago/300/Chicago14.jpg" },
    ]
  },

  {
    '_id': 23,
    'name': 'Denver',
    'url' : '/Denver', 
    'color' : 0xFFFFFF,
    'lat': '39.742043',
    'lon': '-104.991531', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/denverMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver16.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver16.jpg" },
    ]
  },
  

  {
    '_id': 24,
    'name': 'Denver',
    'url' : '/Denver', 
    'color' : 0xFFFFFF,
    'lat': '39.742043',
    'lon': '-104.991531', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/denverMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/1170/denver16.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Denver/300/denver16.jpg" },
    ]
  },

  {
    '_id': 25,
    'name': 'devilsTower',
    'url' : '/devilsTower', 
    'color' : 0xFFFFFF,
    'lat': '44.590279',
    'lon': '-104.715279', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/devilsTower/devilsTowerMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/devilsTower/1170/devilsTower.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/devilsTower/1170/devilsTower2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/devilsTower/1170/devilsTower3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/devilsTower/1170/devilsTower4.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/devilsTower/300/devilsTower.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/devilsTower/300/devilsTower2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/devilsTower/300/devilsTower3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/devilsTower/300/devilsTower4.jpg" },
    ]
  },

  {
    '_id': 26,
    'name': 'Everglades',
    'url' : '/Everglades', 
    'color' : 0xFFFFFF,
    'lat': '25.286615',
    'lon': '-80.898651', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Everglades/EvergladesMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Everglades/1170/Everglades.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Everglades/1170/Everglades2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Everglades/1170/Everglades3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Everglades/1170/Everglades4.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Everglades/300/Everglades.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Everglades/300/Everglades2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Everglades/300/Everglades3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Everglades/300/Everglades4.jpg" },
    ]
  },

  {
    '_id': 27,
    'name': 'GOTG',
    'url' : '/GOTG', 
    'color' : 0xFFFFFF,
    'lat': '37.878445',
    'lon': '-104.869789', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/GOTGMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/1170/GOTG.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/1170/GOTG2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/1170/GOTG3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/1170/GOTG4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/1170/GOTG5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/1170/GOTG6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/1170/GOTG7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/1170/GOTG8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/1170/GOTG9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/1170/GOTG10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/1170/GOTG11.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/300/GOTG.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/300/GOTG2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/300/GOTG3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/300/GOTG4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/300/GOTG5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/300/GOTG6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/300/GOTG7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/300/GOTG8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/300/GOTG9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/300/GOTG10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GOTG/300/GOTG11.jpg" },
    ]
  },

  {
    '_id': 28,
    'name': 'GrandCanyon',
    'url' : '/GrandCanyon', 
    'color' : 0xFFFFFF,
    'lat': '36.056595',
    'lon': '-113.125092', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GrandCanyon/GrandCanyonMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GrandCanyon/1170/GrandCanyon.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GrandCanyon/1170/GrandCanyon2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GrandCanyon/1170/GrandCanyon3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GrandCanyon/1170/GrandCanyon4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GrandCanyon/1170/GrandCanyon5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GrandCanyon/1170/GrandCanyon6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GrandCanyon/1170/GrandCanyon7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GrandCanyon/1170/GrandCanyon8.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GrandCanyon/300/GrandCanyon.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GrandCanyon/300/GrandCanyon2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GrandCanyon/300/GrandCanyon3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GrandCanyon/300/GrandCanyon4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GrandCanyon/300/GrandCanyon5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GrandCanyon/300/GrandCanyon6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GrandCanyon/300/GrandCanyon7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GrandCanyon/300/GrandCanyon8.jpg" },
    ]
  },

  {
    '_id': 29,
    'name': 'GrandTeton',
    'url' : '/GrandTeton', 
    'color' : 0xFFFFFF,
    'lat': '43.7904282',
    'lon': '-110.68176269999998', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GrandTeton/GrandTetonMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GrandTeton/1170/GrandTeton.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/GrandTeton/300/GrandTeton.jpg" },
    ]
  },

  
  {
    '_id': 30,
    'name': 'Hoover',
    'url' : '/Hoover', 
    'color' : 0xFFFFFF,
    'lat': '36.0160655',
    'lon': '-114.7377325', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Hoover/hooverMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Hoover/1170/Hoover.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Hoover/1170/Hoover2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Hoover/1170/Hoover3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Hoover/1170/Hoover4.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Hoover/300/Hoover.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Hoover/300/Hoover2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Hoover/300/Hoover3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Hoover/300/Hoover4.jpg" },
    ]
  },

  
  {
    '_id': 31,
    'name': 'KeyWest',
    'url' : '/KeyWest', 
    'color' : 0x00B5E3,
    'lat': '24.555059',
    'lon': '-81.779984', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/KeyWest/KeyWestMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/KeyWest/1170/KeyWest.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/KeyWest/1170/KeyWest2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/KeyWest/1170/KeyWest3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/KeyWest/1170/KeyWest4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/KeyWest/1170/KeyWest5.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/KeyWest/300/KeyWest.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/KeyWest/300/KeyWest2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/KeyWest/300/KeyWest3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/KeyWest/300/KeyWest4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/KeyWest/300/KeyWest5.jpg" },
    ]
  },

  
  {
    '_id': 32,
    'name': 'LasVegas',
    'url' : '/LasVegas', 
    'color' : 0xFFFFFF,
    'lat': '37.114647',
    'lon': '-115.172813', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/LasVegas/LasVegasMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/LasVegas/1170/LasVegas.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/LasVegas/1170/LasVegas2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/LasVegas/1170/LasVegas3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/LasVegas/1170/LasVegas4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/LasVegas/1170/LasVegas5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/LasVegas/1170/LasVegas6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/LasVegas/1170/LasVegas7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/LasVegas/1170/LasVegas8.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/LasVegas/300/LasVegas.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/LasVegas/300/LasVegas2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/LasVegas/300/LasVegas3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/LasVegas/300/LasVegas4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/LasVegas/300/LasVegas5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/LasVegas/300/LasVegas6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/LasVegas/300/LasVegas7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/LasVegas/300/LasVegas8.jpg" },
    ]
  },


  
  {
    '_id': 33,
    'name': 'MiamiBeach',
    'url' : '/MiamiBeach', 
    'color' : 0xFFFFFF,
    'lat': '25.991681',
    'lon': '-80.001788', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MiamiBeach/miamibeachMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MiamiBeach/1170/miamibeach.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MiamiBeach/1170/miamibeach2.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MiamiBeach/300/miamibeach.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MiamiBeach/300/miamibeach2.jpg" },
    ]
  },

  
  {
    '_id': 34,
    'name': 'Monolith',
    'url' : '/Monolith', 
    'color' : 0xFFFFFF,
    'lat': '35.00455074651473',
    'lon': '-114.07699943863697', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/MonolithGardenMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/1170/MonolithGarden.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/1170/MonolithGarden2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/1170/MonolithGarden3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/1170/MonolithGarden4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/1170/MonolithGarden5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/1170/MonolithGarden6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/1170/MonolithGarden7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/1170/MonolithGarden8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/1170/MonolithGarden9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/1170/MonolithGarden10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/1170/MonolithGarden11.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/300/MonolithGarden.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/300/MonolithGarden2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/300/MonolithGarden3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/300/MonolithGarden4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/300/MonolithGarden5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/300/MonolithGarden6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/300/MonolithGarden7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/300/MonolithGarden8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/300/MonolithGarden9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/300/MonolithGarden10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonolithGarden/300/MonolithGarden11.jpg" },
    ]
  }, 
  
  // -->

  {
    '_id': 35,
    'name': 'MonumentValley',
    'url' : '/MonumentValley', 
    'color' : 0xFF69B4,
    'lat': '38.848572',
    'lon': '-102.829117', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/MonumentValleyMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/1170/MonumentValley.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/1170/MonumentValley2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/1170/MonumentValley3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/1170/MonumentValley4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/1170/MonumentValley5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/1170/MonumentValley6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/1170/MonumentValley7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/1170/MonumentValley8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/1170/MonumentValley9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/1170/MonumentValley10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/1170/MonumentValley11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/1170/MonumentValley12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/1170/MonumentValley13.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/300/MonumentValley.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/300/MonumentValley2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/300/MonumentValley3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/300/MonumentValley4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/300/MonumentValley5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/300/MonumentValley6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/300/MonumentValley7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/300/MonumentValley8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/300/MonumentValley9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/300/MonumentValley10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/300/MonumentValley11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/300/MonumentValley12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/MonumentValley/300/MonumentValley13.jpg" },
    ]
  },

  {
    '_id': 36,
    'name': 'Newyork',
    'url' : '/Newyork', 
    'color' : 0xFFFFFF,
    'lat': '40.730610',
    'lon': '-73.935242', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/NYMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/1170/newyork.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/1170/newyork2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/1170/newyork3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/1170/newyork4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/1170/newyork5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/1170/newyork6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/1170/newyork7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/1170/newyork8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/1170/newyork9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/1170/newyork10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/1170/newyork11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/1170/newyork12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/1170/newyork13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/1170/newyork14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/1170/newyork15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/1170/newyork16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/1170/newyork17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/1170/newyork18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/1170/newyork19.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/300/newyork.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/300/newyork2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/300/newyork3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/300/newyork4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/300/newyork5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/300/newyork6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/300/newyork7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/300/newyork8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/300/newyork9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/300/newyork10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/300/newyork11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/300/newyork12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/300/newyork13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/300/newyork14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/300/newyork15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/300/newyork16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/300/newyork17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/300/newyork18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Newyork/300/newyork19.jpg" },
    ]
  },

  {
    '_id': 37,
    'name': 'Portland',
    'url' : '/Portland', 
    'color' : 0xFFFFFF,
    'lat': '45.523064',
    'lon': '-122.676483', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Portland/PortlandMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Portland/1170/Portland.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Portland/1170/Portland2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Portland/1170/Portland3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Portland/1170/Portland4.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Portland/300/Portland.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Portland/300/Portland2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Portland/300/Portland3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Portland/300/Portland4.jpg" },
    ]
  },

  {
    '_id': 38,
    'name': 'RedRock',
    'url' : '/RedRock', 
    'color' : 0x8B0000,
    'lat': '36.135626 ',
    'lon': '-116.428135', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/redrockMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/1170/redrock.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/1170/redrock2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/1170/redrock3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/1170/redrock4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/1170/redrock5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/1170/redrock6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/1170/redrock7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/1170/redrock8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/1170/redrock9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/1170/redrock10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/1170/redrock11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/1170/redrock12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/1170/redrock13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/1170/redrock14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/1170/redrock15.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/300/redrock.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/300/redrock2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/300/redrock3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/300/redrock4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/300/redrock5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/300/redrock6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/300/redrock7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/300/redrock8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/300/redrock9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/300/redrock10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/300/redrock11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/300/redrock12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/300/redrock13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/300/redrock14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RedRock/300/redrock15.jpg" },
    ]
  },

  {
    '_id': 39,
    'name': 'Rocky',
    'url' : '/Rocky', 
    'color' : 0x7FFFD4,
    'lat': '41.343182',
    'lon': '-105.688103', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/rockyMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/1170/rocky.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/1170/rocky2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/1170/rocky3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/1170/rocky4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/1170/rocky5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/1170/rocky6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/1170/rocky7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/1170/rocky8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/1170/rocky9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/1170/rocky10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/1170/rocky11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/1170/rocky12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/1170/rocky13.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/300/rocky.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/300/rocky2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/300/rocky3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/300/rocky4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/300/rocky5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/300/rocky6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/300/rocky7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/300/rocky8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/300/rocky9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/300/rocky10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/300/rocky11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/300/rocky12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/rocky/300/rocky13.jpg" },
    ]
  },

  {
    '_id': 40,
    'name': 'RRAmphitheater',
    'url' : '/RRAmphitheater', 
    'color' : 0xFFFFFF,
    'lat': '39.966508',
    'lon': '-103.207451', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RRAmphitheater/RRAmphitheaterMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RRAmphitheater/1170/RRAmphitheater.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RRAmphitheater/1170/RRAmphitheater2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RRAmphitheater/1170/RRAmphitheater3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RRAmphitheater/1170/RRAmphitheater4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RRAmphitheater/1170/RRAmphitheater5.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RRAmphitheater/300/RRAmphitheater.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RRAmphitheater/300/RRAmphitheater2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RRAmphitheater/300/RRAmphitheater3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RRAmphitheater/300/RRAmphitheater4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/RRAmphitheater/300/RRAmphitheater5.jpg" },
    ]
  },

  {
    '_id': 41,
    'name': 'Rushmore',
    'url' : '/Rushmore', 
    'color' : 0xFFFFFF,
    'lat': '43.877163',
    'lon': '-102.456009 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Rushmore/RushmoreMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Rushmore/1170/Rushmore.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Rushmore/1170/Rushmore2.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Rushmore/300/Rushmore.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Rushmore/300/Rushmore2.jpg" },
    ]
  },

  {
    '_id': 42,
    'name': 'SaintLouis',
    'url' : '/SaintLouis', 
    'color' : 0xFFFFFF,
    'lat': '38.627003',
    'lon': '-90.199402 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SaintLouis/SaintLouisMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SaintLouis/1170/SaintLouis.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SaintLouis/1170/SaintLouis2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SaintLouis/1170/SaintLouis3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SaintLouis/1170/SaintLouis4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SaintLouis/1170/SaintLouis5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SaintLouis/1170/SaintLouis6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SaintLouis/1170/SaintLouis7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SaintLouis/1170/SaintLouis8.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SaintLouis/300/SaintLouis.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SaintLouis/300/SaintLouis2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SaintLouis/300/SaintLouis3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SaintLouis/300/SaintLouis4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SaintLouis/300/SaintLouis5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SaintLouis/300/SaintLouis6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SaintLouis/300/SaintLouis7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SaintLouis/300/SaintLouis8.jpg" },
    ]
  },


  {
    '_id': 43,
    'name': 'Santa Fe',
    'url' : '/Santa Fe', 
    'color' : 0xFFFFFF,
    'lat': '35.691544',
    'lon': '-105.944183 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/SantafeMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/1170/Santafe.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/1170/Santafe2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/1170/Santafe3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/1170/Santafe4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/1170/Santafe5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/1170/Santafe6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/1170/Santafe7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/1170/Santafe8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/1170/Santafe9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/1170/Santafe10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/1170/Santafe11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/1170/Santafe12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/1170/Santafe13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/1170/Santafe14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/1170/Santafe15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/1170/Santafe16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/1170/Santafe17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/1170/Santafe18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/1170/Santafe19.jpg" },
      { id: 20, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/1170/Santafe20.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/300/Santafe.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/300/Santafe2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/300/Santafe3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/300/Santafe4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/300/Santafe5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/300/Santafe6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/300/Santafe7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/300/Santafe8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/300/Santafe9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/300/Santafe10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/300/Santafe11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/300/Santafe12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/300/Santafe13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/300/Santafe14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/300/Santafe15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/300/Santafe16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/300/Santafe17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/300/Santafe18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/300/Santafe19.jpg" },
      { id: 20, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Santafe/300/Santafe20.jpg" },
    ]
  },


  {
    '_id': 44,
    'name': 'Seattle',
    'url' : '/Seattle', 
    'color' : 0xFFFFFF,
    'lat': '47.608013',
    'lon': '-122.335167 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Seattle/SeattleMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Seattle/1170/Seattle.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Seattle/1170/Seattle2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Seattle/1170/Seattle3.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Seattle/300/Seattle.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Seattle/300/Seattle2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Seattle/300/Seattle3.jpg" },
    ]
  },

  {
    '_id': 45,
    'name': 'Sedona',
    'url' : '/Sedona', 
    'color' : 0xFFD700,
    'lat': '34.871002',
    'lon': '-111.760826 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/SedonaMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/1170/Sedona.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/1170/Sedona2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/1170/Sedona3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/1170/Sedona4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/1170/Sedona5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/1170/Sedona6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/1170/Sedona7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/1170/Sedona8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/1170/Sedona9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/1170/Sedona10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/1170/Sedona11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/1170/Sedona12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/1170/Sedona13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/1170/Sedona14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/1170/Sedona15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/1170/Sedona16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/1170/Sedona17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/1170/Sedona18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/1170/Sedona19.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/300/Sedona.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/300/Sedona2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/300/Sedona3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/300/Sedona4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/300/Sedona5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/300/Sedona6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/300/Sedona7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/300/Sedona8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/300/Sedona9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/300/Sedona10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/300/Sedona11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/300/Sedona12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/300/Sedona13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/300/Sedona14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/300/Sedona15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/300/Sedona16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/300/Sedona17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/300/Sedona18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Sedona/300/Sedona19.jpg" },
    ]
  },


  {
    '_id': 46,
    'name': 'Wasington',
    'url' : '/Wasington', 
    'color' : 0xFFFFFF,
    'lat': '47.751076',
    'lon': '-119.740135 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Wasington/wasingtonMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Wasington/1170/wasington.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Wasington/1170/wasington2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Wasington/1170/wasington3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Wasington/1170/wasington4.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Wasington/300/wasington.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Wasington/300/wasington2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Wasington/300/wasington3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Wasington/300/wasington4.jpg" },
    ]
  },

  {
    '_id': 47,
    'name': 'WhiteSands',
    'url' : '/WhiteSands', 
    'color' : 0xFFFFF0,
    'lat': '32.779720',
    'lon': '-106.171669 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/WhiteSands/WhiteSandsMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/WhiteSands/1170/WhiteSands.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/WhiteSands/1170/WhiteSands2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/WhiteSands/1170/WhiteSands3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/WhiteSands/1170/WhiteSands4.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/WhiteSands/300/WhiteSands.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/WhiteSands/300/WhiteSands2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/WhiteSands/300/WhiteSands3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/WhiteSands/300/WhiteSands4.jpg" },
    ]
  },

  {
    '_id': 48,
    'name': 'YellowStone',
    'url' : '/YellowStone', 
    'color' : 0xBDB76B,
    'lat': '45.423691',
    'lon': '-110.588516 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/YellowStoneMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/1170/YellowStone.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/1170/YellowStone2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/1170/YellowStone3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/1170/YellowStone4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/1170/YellowStone5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/1170/YellowStone6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/1170/YellowStone7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/1170/YellowStone8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/1170/YellowStone9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/1170/YellowStone10.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/300/YellowStone.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/300/YellowStone2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/300/YellowStone3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/300/YellowStone4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/300/YellowStone5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/300/YellowStone6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/300/YellowStone7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/300/YellowStone8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/300/YellowStone9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/YellowStone/300/YellowStone10.jpg" },
    ]
  },

  {
    '_id': 49,
    'name': 'Yosemite',
    'url' : '/Yosemite', 
    'color' : 0x7B68EE,
    'lat': '37.865101',
    'lon': '-119.538330 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/yosemityMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity19.jpg" },
      { id: 20, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity20.jpg" },
      { id: 21, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity21.jpg" },
      { id: 22, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity22.jpg" },
      { id: 23, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity23.jpg" },
      { id: 24, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity24.jpg" },
      { id: 25, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity25.jpg" },
      { id: 26, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity26.jpg" },
      { id: 27, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity27.jpg" },
      { id: 28, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity28.jpg" },
      { id: 29, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity29.jpg" },
      { id: 30, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity30.jpg" },
      { id: 31, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity31.jpg" },
      { id: 32, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity32.jpg" },
      { id: 33, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity33.jpg" },
      { id: 34, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity34.jpg" },
      { id: 35, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity35.jpg" },
      { id: 36, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/1170/yosemity36.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity19.jpg" },
      { id: 20, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity20.jpg" },
      { id: 21, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity21.jpg" },
      { id: 22, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity22.jpg" },
      { id: 23, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity23.jpg" },
      { id: 24, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity24.jpg" },
      { id: 25, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity25.jpg" },
      { id: 26, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity26.jpg" },
      { id: 27, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity27.jpg" },
      { id: 28, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity28.jpg" },
      { id: 29, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity29.jpg" },
      { id: 30, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity30.jpg" },
      { id: 31, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity31.jpg" },
      { id: 32, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity32.jpg" },
      { id: 33, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity33.jpg" },
      { id: 34, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity34.jpg" },
      { id: 35, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity35.jpg" },
      { id: 36, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Yosemite/300/yosemity36.jpg" },
    ]
  },

   {
    '_id': 50,
    'name': 'SanFransico',
    'url' : '/SanFransico', 
    'color' : 0xDEB887,
    'lat': '37.773972',
    'lon': '-122.431297 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/SanFransicoMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico19.jpg" },
      { id: 20, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico20.jpg" },
      { id: 21, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico21.jpg" },
      { id: 22, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico22.jpg" },
      { id: 23, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico23.jpg" },
      { id: 24, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico24.jpg" },
      { id: 25, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/1170/SanFransico25.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico19.jpg" },
      { id: 20, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico20.jpg" },
      { id: 21, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico21.jpg" },
      { id: 22, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico22.jpg" },
      { id: 23, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico23.jpg" },
      { id: 24, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico24.jpg" },
      { id: 25, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/SanFransico/300/SanFransico25.jpg" },
    ]
  },

  {
      '_id': 51,
      'name': 'FirstDay',
      'url' : '/FirstDay', 
      'color' : 0x6495ED,
      'lat': '34.052235',
      'lon': '-118.243683 ', 
      'latTransit': '0',
      'lonTransit': '0',
      'x' : '0',
      'y' : '0',
      'z' : '0',
      'message' : '',
      'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/FirstDay/FirstDayMain.jpg",
      'imgList' : [
        { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/FirstDay/1170/FirstDay.jpg" },
        { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/FirstDay/1170/FirstDay2.jpg" },
        { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/FirstDay/1170/FirstDay3.jpg" },
        { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/FirstDay/1170/FirstDay4.jpg" },
      ],
      'simgList' : [
        { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/FirstDay/300/FirstDay.jpg" },
        { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/FirstDay/300/FirstDay2.jpg" },
        { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/FirstDay/300/FirstDay3.jpg" },
        { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/FirstDay/300/FirstDay4.jpg" },
      ]
    },

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // western eroupe

  
  {
    '_id': 52,
    'name': 'Burano',
    'url' : '/Burano', 
    'color' : 0xFFFFFF,
    'lat': '46.485391',
    'lon': '13.416656 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Burano/BuranoMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Burano/1170/Burano.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Burano/1170/Burano2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Burano/1170/Burano3.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Burano/300/Burano.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Burano/300/Burano2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Burano/300/Burano3.jpg" },
    ]
  },

  {
    '_id': 53,
    'name': 'Firenze',
    'url' : '/Firenze', 
    'color' : 0xFFFFFF,
    'lat': '43.769562',
    'lon': '11.255814 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Firenze/FirenzeMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Firenze/1170/Firenze.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Firenze/1170/Firenze2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Firenze/1170/Firenze3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Firenze/1170/Firenze4.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Firenze/300/Firenze.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Firenze/300/Firenze2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Firenze/300/Firenze3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Firenze/300/Firenze4.jpg" },
    ]
  },

  {
    '_id': 54,
    'name': 'InterLaken',
    'url' : '/InterLaken', 
    'color' : 0xFFFFFF,
    'lat': '46.683334',
    'lon': '7.850000 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/InterLaken/InterLakenMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/InterLaken/1170/InterLaken.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/InterLaken/300/InterLaken.jpg" },
    ]
  },

  {
    '_id': 55,
    'name': 'London',
    'url' : '/London', 
    'color' : 0xCDDC39,
    'lat': '51.509865',
    'lon': '-0.118092 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/LondonMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/1170/London.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/1170/London2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/1170/London3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/1170/London4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/1170/London5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/1170/London6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/1170/London7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/1170/London8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/1170/London9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/1170/London10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/1170/London11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/1170/London12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/1170/London13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/1170/London14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/1170/London15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/1170/London16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/1170/London17.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/300/London.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/300/London2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/300/London3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/300/London4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/300/London5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/300/London6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/300/London7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/300/London8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/300/London9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/300/London10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/300/London11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/300/London12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/300/London13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/300/London14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/300/London15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/300/London16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/London/300/London17.jpg" },
    ]
  },

  
  {
    '_id': 56,
    'name': 'Paris',
    'url' : '/Paris', 
    'color' : 0x8470FF,
    'lat': '48.864716',
    'lon': '2.349014 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/ParisMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/1170/Paris.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/1170/Paris2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/1170/Paris3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/1170/Paris4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/1170/Paris5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/1170/Paris6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/1170/Paris7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/1170/Paris8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/1170/Paris9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/1170/Paris10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/1170/Paris11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/1170/Paris12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/1170/Paris13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/1170/Paris14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/1170/Paris15.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/300/Paris.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/300/Paris2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/300/Paris3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/300/Paris4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/300/Paris5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/300/Paris6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/300/Paris7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/300/Paris8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/300/Paris9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/300/Paris10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/300/Paris11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/300/Paris12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/300/Paris13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/300/Paris14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Paris/300/Paris15.jpg" },
    ]
  },


  
  {
    '_id': 57,
    'name': 'Pisa',
    'url' : '/Pisa', 
    'color' : 0xFFFFFF,
    'lat': '43.716667',
    'lon': '9.400000 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pisa/PisaMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pisa/1170/Pisa.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pisa/1170/Pisa2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pisa/1170/Pisa3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pisa/1170/Pisa4.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pisa/300/Pisa.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pisa/300/Pisa2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pisa/300/Pisa3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pisa/300/Pisa4.jpg" },
    ]
  },


  
  {
    '_id': 58,
    'name': 'Pompeii',
    'url' : '/Pompeii', 
    'color' : 0xFFFFFF,
    'lat': '40.7462',
    'lon': '14.4989 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/PompeiiMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/1170/Pompeii.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/1170/Pompeii2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/1170/Pompeii3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/1170/Pompeii4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/1170/Pompeii5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/1170/Pompeii6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/1170/Pompeii7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/1170/Pompeii8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/1170/Pompeii9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/1170/Pompeii10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/1170/Pompeii11.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/300/Pompeii.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/300/Pompeii2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/300/Pompeii3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/300/Pompeii4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/300/Pompeii5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/300/Pompeii6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/300/Pompeii7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/300/Pompeii8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/300/Pompeii9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/300/Pompeii10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Pompeii/300/Pompeii11.jpg" },
    ]
  },

  
  
  {
    '_id': 59,
    'name': 'Positano',
    'url' : '/Positano', 
    'color' : 0x03A9F4,
    'lat': '39.6281',
    'lon': '15.4850 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/PositanoMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/1170/Positano.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/1170/Positano2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/1170/Positano3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/1170/Positano4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/1170/Positano5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/1170/Positano6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/1170/Positano7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/1170/Positano8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/1170/Positano9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/1170/Positano10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/1170/Positano11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/1170/Positano12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/1170/Positano13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/1170/Positano14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/1170/Positano15.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/300/Positano.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/300/Positano2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/300/Positano3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/300/Positano4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/300/Positano5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/300/Positano6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/300/Positano7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/300/Positano8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/300/Positano9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/300/Positano10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/300/Positano11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/300/Positano12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/300/Positano13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/300/Positano14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Positano/300/Positano15.jpg" },
    ]
  },

  {
    '_id': 60,
    'name': 'Rome',
    'url' : '/Rome', 
    'color' : 0xFFFFFF,
    'lat': '41.902782',
    'lon': '12.496366 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Rome/RomeMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Rome/1170/Rome.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Rome/1170/Rome2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Rome/1170/Rome3.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Rome/300/Rome.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Rome/300/Rome2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Rome/300/Rome3.jpg" },
    ]
  },

  {
    '_id': 61,
    'name': 'Vatican',
    'url' : '/Vatican', 
    'color' : 0xFFFFFF,
    'lat': '42.904755',
    'lon': '13.454628 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Vatican/VaticanMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Vatican/1170/Vatican.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Vatican/1170/Vatican2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Vatican/1170/Vatican3.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Vatican/300/Vatican.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Vatican/300/Vatican2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Vatican/300/Vatican3.jpg" },
    ]
  },

  {
    '_id': 62,
    'name': 'Venice',
    'url' : '/Venice', 
    'color' : 0xA8A6EE,
    'lat': '45.438759',
    'lon': '12.327145', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/VeniceMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice2.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice3.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice4.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice5.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice6.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice7.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice8.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice9.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice10.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice11.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice12.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice13.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice14.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice15.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice16.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice17.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice18.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice19.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice20.jpg" },
      { id: 20, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice21.jpg" },
      { id: 21, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice22.jpg" },
      { id: 22, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice23.jpg" },
      { id: 23, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/1170/Venice24.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice2.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice3.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice4.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice5.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice6.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice7.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice8.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice9.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice10.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice11.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice12.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice13.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice14.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice15.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice16.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice17.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice18.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice19.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice20.jpg" },
      { id: 20, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice21.jpg" },
      { id: 21, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice22.jpg" },
      { id: 22, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice23.jpg" },
      { id: 23, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Western+Europe/Venice/300/Venice24.jpg" },
    ]
  },

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Singapore
  {
    '_id': 63,
    'name': 'Singapore',
    'url' : '/Singapore', 
    'color' : 0xFFD700,
    'lat': '1.290270',
    'lon': '103.851959 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/SingaporeMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore19.jpg" },
      { id: 20, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore20.jpg" },
      { id: 21, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore21.jpg" },
      { id: 22, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore22.jpg" },
      { id: 23, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore23.jpg" },
      { id: 24, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/1170/Singapore24.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore19.jpg" },
      { id: 20, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore20.jpg" },
      { id: 21, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore21.jpg" },
      { id: 22, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore22.jpg" },
      { id: 23, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore23.jpg" },
      { id: 24, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Singapore/300/Singapore24.jpg" },
    ]
  },

  
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // indonesia
 
  {
    '_id': 64,
    'name': 'Jakarta',
    'url' : '/Jakarta', 
    'color' : 0xFFFFFF,
    'lat': '-6.200000',
    'lon': '106.816666 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Jakarta/JakartaMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Jakarta/1170/Jakarta.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Jakarta/300/Jakarta.jpg" },
    ]
  },
   
  {
    '_id': 65,
    'name': 'Palembang',
    'url' : '/Palembang', 
    'color' : 0xB1EA4D,
    'lat': '-2.990934',
    'lon': '104.756554 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/PalembangMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/1170/Palembang.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/1170/Palembang2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/1170/Palembang3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/1170/Palembang4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/1170/Palembang5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/1170/Palembang6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/1170/Palembang7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/1170/Palembang8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/1170/Palembang9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/1170/Palembang10.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/300/Palembang.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/300/Palembang2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/300/Palembang3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/300/Palembang4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/300/Palembang5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/300/Palembang6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/300/Palembang7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/300/Palembang8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/300/Palembang9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Palembang/300/Palembang10.jpg" },
    ]
  },
   
  {
    '_id': 66,
    'name': 'Yogyakarta',
    'url' : '/Yogyakarta', 
    'color' : 0xCE9FFC,
    'lat': '-7.200000',
    'lon': '107.816666 ', 
    'latTransit': '0',
    'lonTransit': '0',
    'x' : '0',
    'y' : '0',
    'z' : '0',
    'message' : '',
    'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/YogyakartaMain.jpg",
    'imgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta19.jpg" },
      { id: 20, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta20.jpg" },
      { id: 21, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta21.jpg" },
      { id: 22, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta22.jpg" },
      { id: 23, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta23.jpg" },
      { id: 24, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta24.jpg" },
      { id: 25, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta25.jpg" },
      { id: 26, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/1170/Yogyakarta26.jpg" },
    ],
    'simgList' : [
      { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta.jpg" },
      { id: 2, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta2.jpg" },
      { id: 3, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta3.jpg" },
      { id: 4, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta4.jpg" },
      { id: 5, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta5.jpg" },
      { id: 6, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta6.jpg" },
      { id: 7, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta7.jpg" },
      { id: 8, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta8.jpg" },
      { id: 9, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta9.jpg" },
      { id: 10, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta10.jpg" },
      { id: 11, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta11.jpg" },
      { id: 12, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta12.jpg" },
      { id: 13, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta13.jpg" },
      { id: 14, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta14.jpg" },
      { id: 15, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta15.jpg" },
      { id: 16, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta16.jpg" },
      { id: 17, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta17.jpg" },
      { id: 18, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta18.jpg" },
      { id: 19, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta19.jpg" },
      { id: 20, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta20.jpg" },
      { id: 21, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta21.jpg" },
      { id: 22, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta22.jpg" },
      { id: 23, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta23.jpg" },
      { id: 24, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta24.jpg" },
      { id: 25, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta25.jpg" },
      { id: 26, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/Indonesia/Yogyakarta/300/Yogyakarta26.jpg" },
    ]
  },

  // {
  //   '_id': 48,
  //   'name': 'Rushmore',
  //   'url' : '/Rushmore', 
  //   'color' : 0xFFFFFF,
  //   'lat': '43.877163',
  //   'lon': '-102.456009 ', 
  //   'latTransit': '0',
  //   'lonTransit': '0',
  //   'x' : '0',
  //   'y' : '0',
  //   'z' : '0',
  //   'message' : '',
  //   'mainImg' : "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Rushmore/RushmoreMain.jpg",
  //   'imgList' : [
  //     { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Rushmore/1170/Rushmore.jpg" },
  //   ],
  //   'simgList' : [
  //     { id: 1, imgSrc: "https://ssyproject.s3.ap-northeast-2.amazonaws.com/Memory/USA/Rushmore/300/Rushmore.jpg" },
  //   ]
  // },














]


export default countryPoint
